import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer');

//Componentes
const Login = () => import('@/views/pages/Login');
const userTable = () => import('@/views/base/userTable');
const videoTable = () => import('@/views/base/videoTable');
const inicio = () => import('@/views/base/Inicio');
const editUser = () => import('@/views/base/editUser');
const editVideo = () => import('@/views/base/editVideo');
const consejoTable = () => import('@/views/base/consejoTable');
const editConsejo = () => import('@/views/base/editConsejo');
const categoriaTable = () => import('@/views/base/categoriaTable');
const editCategoria = () => import('@/views/base/editCategoria');
const cuestionarioTable = () => import('@/views/base/cuestionarioTable');
const editCuestionario = () => import('@/views/base/editCuestionario');
const cuestionarioStats = () => import('@/views/base/cuestionarioStats');
const premioTable = () => import('@/views/base/premioTable');
const editPremio = () => import('@/views/base/editPremio');
const estadisticas = () => import('@/views/base/estadisticas');

const notFound = () => import('@/views/pages/Page404');
const errorServer = () => import('@/views/pages/Page500');

Vue.use(Router)

export default new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/Inicio',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'Inicio',
          name: 'Inicio',
          alias: '/',
          component: inicio
        },
        {
          path: 'user',
          name: 'Usuarios',
          component: userTable,
        },
        {
          path: 'user',
          redirect: 'user',
          name: 'Usuario',
          component: editUser,
          children: [
            {
              path: 'editUser/:id',
              name: 'Información del usuario',
              component: editUser
            }
          ]
        },
        {
          path: 'video',
          name: 'Videos',
          component: videoTable
        },
        {
          path: 'video',
          redirect: '/video',
          name: 'Video',
          component: editVideo,
          children: [
            {
              path: ':nombre/:id',
              name: 'Editar video',
              component: editVideo
            },
            {
              path: 'crearVideo',
              name: 'Crear Video',
              component: editVideo
            }
          ]
        },
        {
          path: 'consejo',
          name: 'Consejos',
          component: consejoTable
        },
        {
          path: 'consejo',
          redirect: '/consejo',
          name: 'Consejo',
          component: editConsejo,
          children: [
            {
              path: 'crearConsejo',
              name: 'Crear Consejo',
              component: editConsejo
            },
            {
              path: ':nombre/:id',
              name: 'Editar Consejo',
              component: editConsejo
            }
          ]
        },
        {
          path: 'categoria',
          name: 'Categorias',
          component: categoriaTable
        },
        {
          path: 'categoria',
          redirect: '/categoria',
          name: 'Categoria',
          component: editCategoria,
          children: [
            {
              path: 'crearCategoria',
              name: 'Crear Categoria',
              component: editCategoria
            },
            {
              path: 'editCategoria/:id',
              name: 'Editar Categoria',
              component: editCategoria
            }
          ]
        },
        {
          path: 'cuestionario',
          name: 'Cuestionarios',
          component: cuestionarioTable
        },
        {
          path: 'cuestionario',
          redirect: '/cuestionario',
          name: 'Cuestionario',
          component: editCuestionario,
          children: [
            {
              path: 'crearCuestionario',
              name: 'Crear cuestionario',
              component: editCuestionario
            },
            {
              path: ':nombre/:id',
              name: 'Editar Cuestionario',
              component: editCuestionario
            },
          ]
        },
        {
          path: 'stats_cuestionario/:id',
          name: 'Estadisticas Cuestionario',
          component: cuestionarioStats
        },
        {
          path: 'premio',
          name: 'Premios',
          component: premioTable
        }, {
          path: 'premio',
          redirect: '/premio',
          name: 'Premio',
          component: editPremio,
          children: [
            {
              path: ':nombre/:id',
              name: 'Editar Premio',
              component: editPremio
            },
            {
              path: 'crearPremio',
              name: 'Crear Premio',
              component: editPremio
            }
          ]
        },
        {
          path: 'estadisticas',
          name: 'Estadísticas',
          component: estadisticas
        },
        {
          path: '500',
          name: 'Server Error',
          component: errorServer
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/ejemplo',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },

      ]
    },
    {
      path: '*',
      name: 'not found',
      alias: '/404',
      component: notFound
    },

  ]
}

